<template>
	<div class="notice_popup view info">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close');"/>
		</div>
		<div class="view-tit">
			<span v-if="div == 'dco'">프로젝트 담당</span><span v-else>회원</span> 개인정보
		</div>
		<div class="mem_count"><img src="/images/admin/logo_sm.png" alt="하이프로"/></div>
		<div class="view">
			<div class="list">
				<div class="label">성명</div><div class="contents">{{pmgMberInfo.mberNm}}</div>
			</div>
			<div class="list">
				<div class="label">회원아이디</div><div class="contents">{{pmgMberInfo.loginId}}</div>
			</div>
			<div class="list">
				<div class="label">휴대폰번호</div><div class="contents">{{pmgMberInfo.hpNo | cell}}</div>
			</div>
			<div class="list">
				<div class="label">이메일</div><div class="contents">{{pmgMberInfo.email}}</div>
			</div>
			<div class="list">
				<div class="label">이메일 수신여부</div><div class="contents"><SelectComp type="text" list="Y:동의,N:미동의" v-model="pmgMberInfo.emailRecvYn" /></div>
			</div>
			<div class="list">
				<div class="label">SMS 수신여부</div><div class="contents"><SelectComp type="text" list="Y:동의,N:미동의" v-model="pmgMberInfo.smsRecvYn" /></div>
			</div>

			<div class="list" v-if="div == 'dco'">
				<div class="label">회사명</div><div class="contents">{{pmgMberInfo.corpNm}}</div>
			</div>
			<div class="list" v-if="div == 'dco'">
				<div class="label">부서명</div><div class="contents">{{pmgMberInfo.deptNm}}</div>
			</div>
			<div class="list" v-if="div == 'dco'">
				<div class="label">직급</div><div class="contents">{{pmgMberInfo.positionNm}}</div>
			</div>

			<div class="list" v-if="div == 'tec'">
				<div class="label">회원소속사</div><div class="contents">{{pmgMberInfo.corpNm || '-'}}</div>
			</div>
			<!-- <div class="list" v-if="div == 'tec'">
				<div class="label">참여소속사</div><div class="contents">{{pmgMberInfo.projectJoinCorpNm || '-'}}</div>
			</div> -->
			<div class="list" v-if="div == 'tec'">
				<div class="label">직원구분</div>
					<div class="contents">
						<span v-if="pmgMberInfo.empDivCd =='' || pmgMberInfo.empDivCd == null">-</span>
						<span v-else><SelectComp type="text" cdId="PRO102" v-model="pmgMberInfo.empDivCd" /></span>
					</div>
			</div>
			<div class="list"  v-if="div == 'tec'">
				<div class="label">생년월일</div><div class="contents">{{pmgMberInfo.birthYyyymmdd | date('yyyy.MM.dd')}}</div>
			</div>
			<div class="list"  v-if="div == 'tec'">
				<div class="label">성별</div><div class="contents">{{pmgMberInfo.gender | gender}}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	// props: {
	// 	param : Number
	// },
	props: ['param'],
	data() {
		return {
			pmgMberSeq : this.param.seq,
			pmgMberInfo : {},
			div: this.param.div,
		}
	},

	mounted() {
		//console.log('this.param : ', this.pmgMberSeq);

		this.getPmgMberInfo();
	},

	methods : {
		getPmgMberInfo() {
			this.$.httpPost('/api/mem/myp/getPmgMberInfo', {pmgMberSeq : this.pmgMberSeq})
				.then(res => {
					this.pmgMberInfo = res.data.pmgMberInfo;

				}).catch(this.$.httpErrorCommon);
		}
	}
}
</script>